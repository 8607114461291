import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

interface RadioOption {
  label: string;
  value: any;
}

interface CheckRadioButtonGroupProps extends UseControllerProps {
  label: string;
  id: string;

  options: RadioOption[];
  className?: string;
}

const CheckRadioButtonGroup: React.FC<CheckRadioButtonGroupProps> = ({
  label,
  id,
  options,
  className = "",
  ...controllerProps
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController(controllerProps);

  return (
    <div>
      <div className="flex justify-between items-center space-x-2">
        <p className="text-[#434249] text-sm">{label}</p>
        <div className="flex space-x-4">
          {options.map((option) => (
            <div
              key={option.value}
              className="inline-flex items-center relative"
            >
              <input
                id={id + option.value}
                type="radio"
                value={option.value}
                checked={value === option.value}
                onChange={() => onChange(option.value)}
                className="relative peer shrink-0 appearance-none size-5 border border-[#222] rounded-sm bg-[#f5fffb]
              mt-1 p-0.5 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-blue-100 disabled:border-steel-400 disabled:bg-steel-400"
              />{" "}
              <svg
                className="
                left-[2px]
                absolute 
                w-4 h-4 mt-1
                hidden peer-checked:block
                pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#22c55e"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <label htmlFor={id + option.value} className="ml-2 black text-sm">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
      {error && <p className="mt-2 text-red-600 text-sm">{error.message}</p>}
    </div>
  );
};

export { CheckRadioButtonGroup };
