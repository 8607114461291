"use client";
import {
  FifthSection,
  FirstSection,
  Footer,
  FourthSection,
  LandingPageTabs,
  MainNav,
  SecondSection,
  ThirdSection,
  DownloadApp,
  FAQs,
  Features,
  Guide,
  Testimonials,
  Hero,
} from "src/components";
import { HeyWowFont } from "../fonts/fonts-config";

export default function Home() {
  return (
    <main className={HeyWowFont.className}>
      <MainNav />
      <Hero />
      <Features />
      <Guide />
      <FAQs />
      <DownloadApp />
      <Testimonials />
      <Footer />
    </main>
  );
}
