import Pagination from "@material-ui/lab/Pagination";
import DataTable, { TableStyles } from "react-data-table-component";
import { Spinner } from "../spinner";

const customStyles: TableStyles = {
  tableWrapper: {
    style: {
      overflowX: "visible",
      overflowY: "visible",
    },
  },
  table: {
    style: {
      position: "relative",
      overflowX: "visible",
      overflowY: "visible",
    },
  },
  header: {
    style: {
      display: "none", // Hides the header as per the design
    },
  },
  subHeader: {
    style: {
      border: "none",
      boxShadow: "none",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#f5f5f5", // Light gray background
      fontSize: "14px", // Adjust the font size
      fontFamily: "'Mark OT', sans-serif",
      color: "#333", // Text color for headers
      fontWeight: "500",
      padding: "3px 3px", // Apply padding
      borderBottom: "2px solid #eaeaea", // Slight border for separation
    },
  },
  rows: {
    style: {
      maxHeight: "45px",
      fontSize: "14px",
      fontFamily: "HeyWow",
      backgroundColor: "#fff", // Default row background
      color: "#333", // Font color
      padding: "0px", // Padding inside rows
      borderBottom: "1px solid #f1f1f1", // Slight row separation
      "&:nth-of-type(even)": {
        backgroundColor: "#f9f9f9", // Alternate row background
      },
      "&:hover": {
        backgroundColor: "#e8f4fd", // Hover effect
      },
    },
    selectedHighlightStyle: {
      "&:nth-of-type(n)": {
        backgroundColor: "#d3eafc", // Selected row highlight
      },
    },
  },
  headCells: {
    style: {
      padding: "0px", // Add padding to head cells
      fontSize: "14px",
      fontWeight: "400",
      color: "#666666", // Header text color
    },
  },
  cells: {
    style: {
      padding: "3px 3px", // Add padding to cells
      fontSize: "14px",
      color: "#666666", // Text color
      fontWeight: "400",
    },
  },
};

interface ITable {
  isLoading?: boolean;
  columns: any[];
  data: any[];
  page?: any;
  onPageChange?: any;
  onRowSelected: any;
  count?: any;
}

export const CustomTable = ({
  isLoading,
  onRowSelected,
  columns,
  data,
  count,
  page,
  onPageChange,
}: ITable) => {
  return (
    <>
      <DataTable
        progressPending={isLoading}
        progressComponent={
          <div className="h-72 flex items-center justify-center">
            <Spinner />
          </div>
        }
        customStyles={customStyles}
        selectableRows
        data={data}
        columns={columns}
        // dense
        selectableRowsSingle
        onSelectedRowsChange={(dt) => onRowSelected(dt?.selectedRows[0])}
        responsive
      />
      {count !== undefined && (
        <div className="flex justify-end mt-4">
          <Pagination
            className="pagination"
            count={count}
            page={page}
            onChange={onPageChange}
            boundaryCount={4}
            disabled={isLoading}
          />
        </div>
      )}
    </>
  );
};
